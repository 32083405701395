<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <div class="mb15">
        <span class="mr15">
          <a-button type="primary" v-has:systemRoleAdd="create"> 添加角色 </a-button>
        </span>
      </div>
      <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search" :onFinish="pageSearchChange" layout="inline">
        <a-form-item label="关键词" name="name">
          <a-input v-model:value="search.keywords" :style="{ width: '250px' }" placeholder="请输入角色名称"></a-input>
        </a-form-item>
        <!-- <a-form-item label="用户状态" name="level">
          <a-select v-model:value="search.status" :style="{ width: '180px' }" placeholder="全部">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option :value="1"> 正常 </a-select-option>
            <a-select-option :value="0"> 禁用 </a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" htmlType="submit">搜索</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="pageSearchReset">重置</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination="false" :loading="listLoading" bordered rowKey="id" :columns="columns" :data-source="data">
        <template v-slot:role_name="{ record }">
          <a-tag color="#05486A" v-if='record.is_super'>默认</a-tag>
          <span>{{record.role_name}}</span>
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown-button>
            <a class="ant-dropdown-link"> 操作</a>
            <template v-slot:overlay>
              <a-menu>
                <!--  <a-menu-item>
                  <a  v-has:systemRoleDetail="()=>systemRoleDetail(record,'detail')" href="javascript:;">详情</a>
                </a-menu-item>-->
                <!-- <a-menu-item v-if="record.status == 1">
                  <a v-has:systemRoleStatus="() => systemRoleStatus(0, record)" href="javascript:;">禁用</a>
                </a-menu-item>
                <a-menu-item v-if="record.status != 1">
                  <a v-has:systemRoleStatus="() => systemRoleStatus(1, record)" href="javascript:;">启用</a>
                </a-menu-item> -->
                <a-menu-item>
                  <a v-has:systemRoleSetting="() => systemRoleSetting(record)" href="javascript:;">设置权限</a>
                </a-menu-item>
                <a-menu-item v-if="!record.is_super">
                  <a v-has:systemRoleEdit="() => systemRoleEdit(record, 'edit')" href="javascript:;">修改</a>
                </a-menu-item>
                <a-menu-item v-if="!record.is_super">
                  <a v-has:systemRoleDel="() => systemRoleDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <a-pagination size="small" :style="{ marginTop: '20px', textAlign: 'right' }" :current="search.page" :page-size="search.limit" :total="total" show-size-changer show-quick-jumper @change="pageCurrentChange" @showSizeChange="pageSizeChange"  :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <!--新增角色-->
  <a-modal v-model:visible="visible" :title="title" @ok="handleOk">
    <a-form ref="ruleForm" :model="ruleFormData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item ref="role_name" label="角色名称" name="role_name">
        <a-input v-model:value="ruleFormData.role_name" />
      </a-form-item>
      <!--  <a-form-item ref="description" label="角色描述" name="description" help='(最多10个字)'>
        <a-input v-model:value="ruleFormData.description" />
      </a-form-item>-->
    </a-form>
  </a-modal>
  <!--角色修改权限-->
  <a-modal v-model:visible="visibleSeting" title="权限设置" @ok="handleSeting">
    <a-tree checkable :tree-data="treeData" v-model:checkedKeys="checkedKeys"   :replaceFields="{key:'id'}" @check="onCheck">
    </a-tree>
  </a-modal>
</template>
<script>
import { ref, onMounted, provide, inject, reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
import { getRole, createRole, roleDetail, delRole, editRole, statusRole } from "@/api/system";
import { $iscode } from "@/utils/app";
import { getRouter } from "@/utils/app";
import { setPageState, getPageState } from "@/utils/pageState";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
};
let defForm = {
  role_name: "",
  description: "",
  menu_ids: [],
  id: null,
};
export default {
  setup(props) {
    const state = reactive({
      total: 0,
      data: [],
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 10 },
      title: "新增角色",
      rules: {
        role_name: [
          {
            required: true,
            message: "请输入角色名称",
          },
        ],
      },
      listLoading: false,
      visibleSeting: false,
      treeData: [],
      checkedKeys: [],
      settingData: {},
      halfCheckedKeys:[]
    });
    // // 初始化 分页信息和筛选项信息
    let total = ref(1);
    let search = ref({ ...defSearch });
    let columns = [
      {
        title: "角色ID",
        dataIndex: "id",
        key: "id",
        width:120,
      },
      {
        title: "角色名称",
        dataIndex: "role_name",
        key: "role_name",
        slots: { customRender: "role_name" },
      },
      // {
      //   title: "描述",
      //   dataIndex: "name",
      //   key: "name",
      // },
      // {
      //   title: "状态",
      //   dataIndex: "status",
      //   key: "status",
      //   width:120,
      //   slots: { customRender: "status" },
      // },
      {
        title: "操作",
        key: "action",
        width:120,
        slots: { customRender: "action" },
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        limit: search.value.limit,
      };
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    const initData = async (values) => {
      state.listLoading = true;
      try {
        let res = await getRole(values)
          .then((res) => res.data)
          .catch((error) => error);
        state.listLoading = false;
        if ($iscode(res)) {
          state.data = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.message);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };
    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    //新增角色
    const ruleForm = ref();
    const ruleFormData = ref(JSON.parse(JSON.stringify(defForm)));
    const create = function () {
      state.visible = true;
      state.title='新增角色'
    };
    const handleOk = () => {
      ruleForm.value
        .validate()
        .then(async (e) => {
          let result;
          if (ruleFormData.value.id) {
            result = await editRole(ruleFormData.value.id, ruleFormData.value)
              .then((res) => res.data)
              .catch((error) => error);
          } else {
            result = await createRole(ruleFormData.value)
              .then((res) => res.data)
              .catch((error) => error);
          }
          if ($iscode(result, true)) {
            ruleForm.value.resetFields();
            state.visible = false;
            initData(search.value);
          }
            ruleFormData.value=JSON.parse(JSON.stringify(defForm))
        })
        .catch((error) => {});
    };
    // 设置权限
    const $router = inject("$router");
    const _lodash = inject("_lodash");
    const ids = ref();
    const systemRoleSetting = async (data) => {
      state.settingData.id = data.id;
      let result = await roleDetail(data.id)
        .then((res) => res.data)
        .catch((error) => error);
      // 设置树形图
      state.treeData = result.data.menus;
      // 设置修改的值
      let { id, is_super, role_name } = result.data;
      ruleFormData.value["id"] = id;
      ruleFormData.value["is_super"] = is_super;
      ruleFormData.value["role_name"] = role_name;
      // 设置选中的IDS 和key
      ids.value = result.data.exclude;
      state.checkedKeys = getKey([],result.data.menus);
      state.halfCheckedKeys=result.data.parent
      // 打开弹窗
      state.visibleSeting = true;
  
    };
    const getKey = (result, list) => {
      list.forEach((e) => {
        if (e.is_select) {
          result.push(e.id);
        }
        if (e.children) {
          result.push(...getKey([], e.children));
        }
      });
      return result;
    };
    // 树状图 选中事件
    const onCheck = (checkedKeys, { checked, node, checkedNodes,halfCheckedKeys }) => {
      state.halfCheckedKeys=halfCheckedKeys;
      ids.value = _lodash.map(_lodash.map(checkedNodes, "props"), "id");
    };
    // 权限设置
    const handleSeting = async () => {
      let result = await editRole(state.settingData.id, {
        ...ruleFormData.value,
        menu_ids: ids.value,
        menu_parent_ids:state.halfCheckedKeys
      })
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(result, true)) {
        initData(search.value);
        state.visibleSeting = false;
      }
    };
    // 角色删除
    const $Modal = inject("$Modal");
    const systemRoleDel = (data) => {
      $Modal.confirm({
        content: "确认删除所选角色",
        centered:true,
        onOk: () => {
          delRole(data.id).then((res) => {
            if ($iscode(res.data, true)) {
              initData(search.value);
            }
          });
        },
      });
    };
    const systemRoleEdit = async (data) => {
       state.title='修改角色'
      let result = await roleDetail(data.id)
        .then((res) => res.data)
        .catch((error) => error);
      let { id, is_super, role_name } = result.data;
      ruleFormData.value["id"] = id;
      ruleFormData.value["is_super"] = is_super;
      ruleFormData.value["role_name"] = role_name;
      // 打开弹窗
      state.visible = true;
    };

    const systemRoleStatus = async (status, data) => {
      $Modal.confirm({
        content: `确定${status ? "启用" : "禁用"}该角色？`,
        centered:true,
        onOk: async () => {
          let result = await statusRole(status, data.id)
            .then((res) => res.data)
            .catch((error) => error);
          if ($iscode(result, true)) {
            initData(search.value);
          }
        },
      });
    };
    return {
      ...toRefs(state),
      ruleForm,
      ruleFormData,
      search,
      columns,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pageSizeChange,
      create,
      handleOk,
      systemRoleSetting,
      handleSeting,
      onCheck,
      systemRoleDel,
      systemRoleEdit,
      systemRoleStatus,
    };
  },
};
</script>
